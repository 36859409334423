// XactimatePreviewPage.jsx
import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row, Image, Form } from "react-bootstrap";
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import XactimatePreviewer from './XactimatePdfPreviewer';
import logo from "../assets/logo-2.png";
import { toast } from "react-toastify";
import { generateEstimate, searchLineItems } from "../services/uploads/upload.service"; // Adjust import as necessary
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import XactimatePdfHeaderPage from "./XactimatePdfHeaderPage";

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: 'white',
    color: 'black',
    borderColor: '#ced4da',
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: 'white',
    color: 'black',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#e9ecef' : 'white',
    color: 'black',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'black',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#6c757d',
  }),
};

const XactimatePreviewPage = ({ estimatePdf, propertyPdf, data }) => {
  const [items, setItems] = useState([]);
  const [invoiceData, setInvoiceData] = useState({
    number: 'INV-1001',
    clientName: 'John Doe',
    date: '2023-10-24',
    items: [],
    total: 0,
    itemsBySection: {},
  });
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;

  const sectionOptions = [
    { value: 'Roofing', label: 'Roofing' },
    { value: 'Interior', label: 'Interior' },
    { value: 'Misc', label: 'Misc' },
  ];

  const addNoteToItem = (index) => {
    const updatedItems = [...items];
    updatedItems[index].notes.push({ text: '', bold: false });
    setItems(updatedItems);
  };

  const fetchLineItems = async (inputValue) => {
    try {
      const formData = new FormData();
      formData.append('searchTerm', inputValue);  
      formData.append('page', 1);
      const response = await searchLineItems(formData);
      return response;
    } catch (error) {
      console.error('Error fetching line items:', error);
      toast.error('Error fetching line items. Please try again.');
      return null;
    }
  };

  const loadOptions = async (inputValue) => {
    if (!inputValue) {
      return [];
    }
    const response = await fetchLineItems(inputValue);
    if (response && response.data && response.data.lineItems) {
      const options = response.data.lineItems.map(item => ({
        value: item.Description,
        label: item.Description,
        componentCode: item.componentCode,
        unitPrice: item.UnitPrice || 0,
        unit: item.Unit,
      }));
      return options;
    }
    return [];
  };

  const removeItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };

  const handleNoteChange = (itemIndex, noteIndex, field, value) => {
    const updatedItems = [...items];
    updatedItems[itemIndex].notes[noteIndex][field] = value;
    setItems(updatedItems);
  };

  const removeNoteFromItem = (itemIndex, noteIndex) => {
    const updatedItems = [...items];
    updatedItems[itemIndex].notes.splice(noteIndex, 1);
    setItems(updatedItems);
  };

  const parseData = () => {
    const lineItemsText = data.line_items;
  
    if (!lineItemsText) {
      console.error('line_items is undefined or empty.');
      return;
    }
  
    const pattern = /\*\*(\d+):\s*(.*?)\s*:\*\*[\s\S]*?- \*\*Quantity:\*\* ([\d\.]+ \w+)/g;
    const sectionPattern = /##\s*(\w+)/g; // Regex to match section headers like "## Roofing"
  
    const matches = [...lineItemsText.matchAll(pattern)];
    const sections = [...lineItemsText.matchAll(sectionPattern)];
  
    let currentSection = 'Unknown'; // Default section if no header is found
  
    const parsedItems = [];
    let sectionIndex = 0;
  
    matches.forEach(match => {
      // Check if the current item has moved into the next section
      if (sectionIndex < sections.length && match.index > sections[sectionIndex].index) {
        currentSection = sections[sectionIndex][1]; // Update the section to the matched section name
        sectionIndex += 1;
      }
      parsedItems.push({
        type: 'lineItem',
        description: match[2].trim(),
        quantity: parseFloat(match[3]),
        price: 0,
        notes: [],
        section: currentSection,
        componentCode: '',
        unitPrice: 0,
        unit: '',
      });
    });
  
    setItems(parsedItems);
  };

  // Run parseData only once when the component mounts
  useEffect(() => {
    if (data) {
      parseData();
    }
  }, [data]);

  // Function to add a new line item manually
  const addLineItem = () => {
    setItems([
      ...items,
      {
        type: 'lineItem',
        description: '',
        quantity: 1,
        price: 0,
        notes: [],
        section: 'Roofing', // Default section
        componentCode: '',
        unitPrice: 0,
        unit: '',
      },
    ]);
  };

  const handleItemChange = (index, field, value) => {
    const updatedItems = [...items];

    if (field === 'description') {
      const selectedOption = value;
      if (selectedOption) {
        updatedItems[index]['description'] = selectedOption.label;
        updatedItems[index]['componentCode'] = selectedOption.componentCode;
        updatedItems[index]['unitPrice'] = selectedOption.unitPrice || 0;
        updatedItems[index]['unit'] = selectedOption.unit;
        updatedItems[index]['price'] = selectedOption.unitPrice || 0;
      } else {
        // Clear the description and related fields
        updatedItems[index]['description'] = '';
        updatedItems[index]['componentCode'] = '';
        updatedItems[index]['unitPrice'] = 0;
        updatedItems[index]['unit'] = '';
        updatedItems[index]['price'] = 0;
      }
    } else if (field === 'quantity') {
      updatedItems[index]['quantity'] = parseInt(value) || 1;
    } else {
      updatedItems[index][field] = value;
    }

    setItems(updatedItems);
  };

  useEffect(() => {
    const total = items.reduce(
      (sum, item) => sum + item.quantity * item.price,
      0
    );

    // Group items by section
    const itemsBySection = {};

    items.forEach(item => {
      const section = item.section || 'Section 1';
      if (itemsBySection[section]) {
        itemsBySection[section].push(item);
      } else {
        itemsBySection[section] = [item];
      }
    });

    setInvoiceData(prevData => ({
      ...prevData,
      items: items,
      itemsBySection,
      total: total,
    }));
  }, [items]);

  const totalPages = Math.ceil(items.length / itemsPerPage);

  const goToNextPage = () => {
    setCurrentPage(prev => Math.min(prev + 1, totalPages - 1));
  };

  const goToPreviousPage = () => {
    setCurrentPage(prev => Math.max(prev - 1, 0));
  };

  const renderItems = () => {
    // Get the items for the current page
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = items.slice(startIndex, endIndex);

    return currentItems.map((item, index) => {
      const globalIndex = startIndex + index; // Adjust index to match the items array
      return (
        <React.Fragment key={globalIndex}>
          <div className="item-group">
            {/* Description, Quantity, and Section */}
            <Row className="mb-3">
              <Col lg={4}>
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <AsyncSelect
                    styles={customSelectStyles}
                    cacheOptions
                    loadOptions={loadOptions}
                    defaultOptions
                    onChange={(selectedOption) =>
                      handleItemChange(globalIndex, 'description', selectedOption)
                    }
                    placeholder="Select an item..."
                    isClearable
                    value={item.description ? { label: item.description, value: item.description } : null}
                  />
                </Form.Group>
              </Col>
              <Col lg={2}>
                <Form.Group>
                  <Form.Label>Qty</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Qty"
                    value={item.quantity}
                    onChange={(e) =>
                      handleItemChange(globalIndex, 'quantity', e.target.value)
                    }
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label>Section</Form.Label>
                  <Select
                    styles={customSelectStyles}
                    options={sectionOptions}
                    value={
                      sectionOptions.find(option => option.value === item.section)
                    }
                    onChange={(selectedOption) =>
                      handleItemChange(
                        globalIndex,
                        'section',
                        selectedOption ? selectedOption.value : ''
                      )
                    }
                    isClearable
                    isSearchable
                    placeholder="Select a section..."
                  />
                </Form.Group>
              </Col>
              <Col lg={3} className="d-flex align-items-end">
                <Button
                  variant="danger"
                  onClick={() => removeItem(globalIndex)}
                  block
                >
                  Remove
                </Button>
              </Col>
            </Row>

            {/* Notes Input */}
            {item.notes.map((note, noteIndex) => (
              <Row key={noteIndex} className="mb-2">
                <Col lg={8}>
                  <Form.Group>
                    <Form.Label>Note {noteIndex + 1}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter note..."
                      value={note.text}
                      onChange={(e) =>
                        handleNoteChange(globalIndex, noteIndex, 'text', e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col lg={2} className="d-flex align-items-center">
                  <Form.Check
                    type="checkbox"
                    label="Bold"
                    checked={note.bold}
                    onChange={(e) =>
                      handleNoteChange(globalIndex, noteIndex, 'bold', e.target.checked)
                    }
                  />
                </Col>
                <Col lg={2} className="d-flex align-items-end">
                  <Button
                    variant="danger"
                    onClick={() => removeNoteFromItem(globalIndex, noteIndex)}
                    block
                  >
                    Remove
                  </Button>
                </Col>
              </Row>
            ))}

            {/* Add Note Button */}
            <Row className="mb-3">
              <Col lg={12}>
                <Button
                  variant="secondary"
                  onClick={() => addNoteToItem(globalIndex)}
                  block
                >
                  Add Note to Item
                </Button>
              </Col>
            </Row>
          </div>
        </React.Fragment>
      );
    });
  };

  const headers = {
    "Content-Type": "multipart/form-data"
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("estimate_pdf", estimatePdf);
      formData.append("property_pdf", propertyPdf);
      formData.append("line_items", JSON.stringify(items));
      const response = await generateEstimate(formData, headers);
      console.log("Response:", response);
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  return (
    <Container className="my-5" style={{ maxWidth: "100%", backgroundColor: "rgba(70, 80, 90, 0.7)", padding: "20px", borderRadius: "8px" }}>
      <Row>
        <Col lg={12} className="text-center mb-3" style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.2)", color: "#ffffff" }}>
          <Image src={logo} />
          <h1 className="mt-5 mb-4">Xactimate Estimate</h1>
        </Col>
      </Row>

      <Row>
        <Col lg={6} className="my-4">
          <PDFViewer width="100%" height="930">
            <XactimatePdfHeaderPage data={invoiceData} />
          </PDFViewer>
          <PDFDownloadLink
              document={< XactimatePdfHeaderPage data={invoiceData} />}
              fileName="second-page-only.pdf"
            >
              {({ loading }) => (loading ? 'Loading document...' : 'Download Second Page Only')}
            </PDFDownloadLink>
        </Col>
        <Col lg={6} className="my-4">
          {renderItems()}

          {/* Pagination Controls */}
          {totalPages > 1 && (
            <Row className="mb-4">
              <Col className="d-flex justify-content-center">
                <Button
                  variant="secondary"
                  onClick={goToPreviousPage}
                  disabled={currentPage === 0}
                  className="mr-2"
                >
                  Previous
                </Button>
                <span
                  className="align-self-center mx-2"
                  style={{ color: "#ffffff" }}
                >
                  Page {currentPage + 1} of {totalPages}
                </span>
                <Button
                  variant="secondary"
                  onClick={goToNextPage}
                  disabled={currentPage === totalPages - 1}
                  className="ml-2"
                >
                  Next
                </Button>
              </Col>
            </Row>
          )}

          <Row className="mt-4">
            <Col xs={12} className="mb-2">
              <Button variant="primary" onClick={addLineItem} block>
                Add Line Item
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Button
        className="step-button border-0 px-4 py-2"
        onClick={handleSubmit}>
        <p className="m-0">NEXT STEP</p>
      </Button>
    </Container>
  );
};

export default XactimatePreviewPage;
