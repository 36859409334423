import { GetApiData, PostFormData } from "../../utils/http-client";
import axios from "axios";
import { Config } from '../../config';
const baseURL =process.env.NEXT_PUBLIC_APP_BACKEND_URL;
// process.env.NODE_ENV === "development"
//   ? "http://127.0.0.1:8000"
//   : 
//   "https://backend.claimscortex.com";

const appUrl = process.env.NEXT_PUBLIC_APP_FRONTEND_URL;
// process.env.NODE_ENV === "development"
  // ? "http://127.0.0.1:3001"
  // : "https://app.claimscortex.com";
console.log(baseURL);
console.log(appUrl);
const api = axios.create({
  baseURL: baseURL // Replace with actual base URL
  // Add any other configuration as needed
});

export const getEstimate = async (headers) => {
    try {
      const response = await api.get(`/getEstimate`, { headers });
      return response.data;
    } catch (error) {
      console.error("Error fetching estimate PDF:", error);
      throw error;
    }
  };

export const searchLineItems =  function (data,headers) {
  return PostFormData(`/searchLineItems`, 'POST', data, true,headers);
}

const api2 = axios.create({ baseURL: 
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:8000"
    : "https://backend.dev.claimscortex.com"
 });
export const getEsxEstimate = function (data, headers, responseType) { 
  return PostFormData(`/getEsxEstimate`, 'POST', data, true, headers, responseType);
};
export const getCompletedJobs = function (data, headers) {
  return PostFormData(`/getCompletedJobs`, 'POST', data, true,headers);
}

export const getXactimateEstimate = function (data, headers, responseType) {
  return PostFormData(`/getXactimateEstimate`, 'POST', data, true, headers, responseType);
};



export const uploadPDF = function (data,headers) {
    return PostFormData(`/generateFromPDF`, 'POST', data, true,headers);
}

export const getAccessories = function (data,headers) {
  return PostFormData(`/getAccessories`, 'POST', data, true,headers);
}

export const getLineItems = function (data,headers) {
  return PostFormData(`/getLineItems`, 'POST', data, true,headers);
}

export const saveLineItems = async (data, headers) => {
  return PostFormData(`/saveLineItems`, 'POST', data, true, headers);
};

export const uploadAdjusterMail = function (data,headers) {
    return PostFormData(`/generateFromEmail`, 'POST', data, true,headers);
}
export const generateMailfromPDF = function (data,headers) {
    return PostFormData(`/emailPDF`, 'POST', data, true,headers);
}
export const generateMailfromText = function (data,headers) {
    return PostFormData(`/emailText`, 'POST', data, true,headers);
}
export const generateEstimate = function (data,headers) {
    return PostFormData(`/generateEstimate`, 'POST', data, true,headers);
}
export const generateAudioFile = function (data,headers) {
    return PostFormData(`/getAudioFile`, 'POST', data, true,headers);
}

