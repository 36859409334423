'use client';

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Button,
  Card,
  Form,
  ListGroup,
  Table
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../global.css'; // Custom global styling
import { getAccount, updateAccount, deleteJob, restartJob } from "../services/account/account.service";
import { getXactimateEstimate, getCompletedJobs, getEsxEstimate } from "../services/uploads/upload.service";

import { FaUser, FaFolder, FaQuestionCircle } from 'react-icons/fa';
import { BsBell, BsPersonCircle } from 'react-icons/bs';

import { FiPlus } from 'react-icons/fi';
import XactimateChatbotWidget from './chatbot/XactimateChatbotWidget'; // Import the chatbot widget
import logo from "../assets/logo-2.png";

const EditAccount = () => {
  const [profileData, setProfileData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    oldPassword: '',
    newPassword: '',
    verifyNewPassword: '',
    tokenCount: 0,
  });

  const [files, setFiles] = useState([]);
  const [error, setError] = useState("");
  const [selectedOption, setSelectedOption] = useState('editProfile'); 
  const [termsText, setTermsText] = useState('');

  const navigate = useNavigate();
  const authToken = localStorage.getItem('authToken');
  const headers = {
    'Authorization': `${authToken}`,
    'Content-Type': 'application/json',
    'accountId': localStorage.getItem('accountId')
  };

  useEffect(() => {
    handleGetUser();
    handleGetFiles();
  }, []);

  useEffect(() => {
    if (selectedOption === 'ToS') {
      fetch('/terms.txt')
        .then(response => {
          if (!response.ok) throw new Error('Failed to load terms text');
          return response.text();
        })
        .then(text => setTermsText(text))
        .catch(err => console.error(err));
    }
  }, [selectedOption]);

  const handleGetUser = async () => {
    const isAuthenticated = authToken && authToken.trim() !== '';
    if (isAuthenticated) {
      const accountId = localStorage.getItem('accountId');

      console.log('accountId:', accountId);
      const requestData = { accountId: accountId };
      console.log('requestData:', requestData); 
      console.log('headers:', headers); 
      
      try {
        const response = await getAccount(JSON.stringify(requestData), headers);
        console.log('response:', response);
        if (response && response.data) {
          setProfileData(prev => ({
            ...prev,
            firstName: response.data.account.FirstName || prev.firstName,
            lastName: response.data.account.LastName || prev.lastName,
            email: response.data.account.Email || prev.email,
            tokenCount: response.data.account.Tokens || prev.tokenCount,
          }));
        } else {
          console.error("Invalid response data:", response);
        }
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    } else {
      navigate('/login');
    }
  };

  const handleGetFiles = async () => {
    if (files && files.length > 0) {
      return; 
      // If there are already files, do nothing and return early
    }
    try {
      const accountId = localStorage.getItem('accountId');
      const formData = new FormData();
      formData.append('accountId', accountId);

       const response = await getCompletedJobs(formData, {
        "Content-Type": "application/json"
      });

      console.log('response:', response);
      if (response.data.message === "success" && response.data.completed_jobs) {
        const transformedFiles = response.data.completed_jobs.map((job, index) => ({
          id: index + 1,
          jobId: job.id,
          name: job.estimates_pdf_name,
          uploadedAt: job.upload_date.split('T')[0],
          status: job.job_status === 'completed' ? 'Completed' : job.job_status === 'started' ? 'In Progress' : 'Inactive',
        }));

        setFiles(transformedFiles);
      } else {
        console.error('Failed to fetch the jobs:', response.statusText);
        setFiles([]);
      }
    } catch (error) {
      console.error('Error fetching the jobs:', error);
      setFiles([]);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData(prev => ({ ...prev, [name]: value }));
  };

  const handleViewFile = async (fileId, fileName) => {
    try {
      const accountId = localStorage.getItem('accountId');
      const authToken = localStorage.getItem('authToken');
      const viewHeaders = {
        'Authorization': `${authToken}`,
        'Content-Type': 'application/json',
        'accountId': accountId,
      };
      const requestData = {
        accountId: accountId,
        jobId: fileId,
      };

      const response = await getXactimateEstimate(requestData, viewHeaders, 'blob');

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      } else {
        console.error('Failed to fetch the PDF');
      }
    } catch (error) {
      console.error('Error fetching the PDF:', error);
    }
  };
// Example: Handle deleting a job
const handleDeleteJob = async (fileId) => {
  try {
    const accountId = localStorage.getItem('accountId');
    const authToken = localStorage.getItem('authToken');

    // Prepare your headers
    const viewHeaders = {
      'Authorization': `${authToken}`,
      'Content-Type': 'application/json',
      'accountId': accountId,
    };

    // Prepare your POST body
    const requestData = {
      accountId: accountId,
      jobId: fileId,
    };

    // Send delete request
    const response = await deleteJob(requestData, viewHeaders, 'json');
    
    console.log('Delete response:', response);

    if (response) {
      alert('Job deleted successfully!');
      // Remove the deleted job from your files state
      setFiles(prevFiles => prevFiles.filter(file => file.jobId !== fileId));
    } else {
      alert('No job found or delete failed.');
    }
  } catch (error) {
    console.error('Error in handleDeleteJob:', error);
    alert('Error deleting the job. Check console for details.');
  }
};

// Example: Handle restarting a job
const handleRestartJob = async (fileId) => {
  try {
    console.log('Restarting job:', fileId);
    const accountId = localStorage.getItem('accountId');
    const authToken = localStorage.getItem('authToken');

    // Prepare headers
    const viewHeaders = {
      'Authorization': `${authToken}`,
      'Content-Type': 'application/json',
      'accountId': accountId,
    };

    // Prepare body
    const requestData = {
      accountId: accountId,
      jobId: fileId,
    };

    // Send restart request
    const response = await restartJob(requestData, viewHeaders, 'json');
    
    console.log('Restart response:', response);

    if (response) {
      alert('Job restarted (set to inactive) successfully!');
      // Update the corresponding file's status to 'Inactive'
      setFiles(prevFiles => 
        prevFiles.map(file =>
          file.jobId === fileId 
            ? { ...file, status: 'Inactive' } 
            : file
        )
      );
    } else {
      alert('Failed to restart job.');
    }
  } catch (error) {
    console.error('Error in handleRestartJob:', error);
    alert('Error restarting the job. Check console for details.');
  }
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (profileData.newPassword !== profileData.verifyNewPassword) {
      alert('Passwords do not match');
      return;
    }

    const isAuthenticated = authToken && authToken.trim() !== '';
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }

    try {
      const response = await updateAccount(JSON.stringify(profileData), headers);
      if (response.status === 200) {
        alert('Account updated successfully');
      } else {
        alert('Failed to update account');
      }
    } catch (error) {
      console.error('Error during account update:', error);
    }
  };

  if (!authToken || authToken.trim() === '') {
    return null; 
  }

  const getSectionTitle = () => {
    switch (selectedOption) {
      case 'editProfile':
        return 'Edit Profile';
      case 'files':
        return 'Your Files';
      case 'jobs':
        return 'Jobs';
      case 'ToS':
        return 'Terms of Service';
      case 'chatbot':
        return 'Chat Bot'; // Added a title for Chat Bot
      default:
        return '';
    }
  };

  // Handler for chatbot response if needed
  const handleChatbotResponse = (final_output) => {
    console.log('Chatbot final_output:', final_output);
  };
  const handleViewEsxFile = async (fileName, jobId) => {
    try {
      const accountId = localStorage.getItem('accountId');
      const authToken = localStorage.getItem('authToken');
      const viewHeaders = {
        'Authorization': `${authToken}`,
        'Content-Type': 'application/json',
        'accountId': accountId,
      };
      const requestData = {
        accountId: accountId,
        jobId: jobId, // changed to esx_file_name
      };
  
      const response = await getEsxEstimate(requestData, viewHeaders, 'blob');
  
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const fileNameWithoutPdf = fileName.replace('.pdf', '.esx'); // Remove ".pdf" and add ".esx"

        // Create a temporary link to trigger download
        const a = document.createElement('a');
        a.href = url;
        a.download =fileNameWithoutPdf; // The name of the downloaded file
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        console.error('Failed to fetch the ESX file');
      }
    } catch (error) {
      console.error('Error fetching the ESX file:', error);
    }
  };
  return (
    <Container fluid className="p-0" style={{ color: '#000' }}>
      {/* Top NavBar */}
      <Navbar className="px-3 py-2" style={{ borderBottom: '1px solid #e5e5e5', backgroundColor: 'rgb(25 26 39)', color:'#fff' }}>
        <div className="d-flex align-items-center w-100 justify-content-between" style={{color:'#fff'}}>
          <div className="d-flex align-items-center gap-3" style={{color:'#fff'}}>
            <strong className="ms-2" style={{color:'#fff'}}>
              {profileData.firstName} {profileData.lastName}
            </strong>
            
          </div>
          <div className="d-flex align-items-center gap-3" style={{color:'#fff'}}>
            
            <BsBell size={20} />
            <BsPersonCircle size={24} />
          </div>
        </div>
      </Navbar>

      <Row className="g-0" style={{ minHeight: '100vh', color:'#000' }}>
        {/* Left Sidebar Nav */}
        <Col xs={2} style={{ backgroundColor: 'rgb(25 26 39)' }}>
          <Nav className="flex-column py-4" variant="pills" fill>
            <Nav.Link
              href="#"
              onClick={() => setSelectedOption('editProfile')}
              active={selectedOption === 'editProfile'}
              className="d-flex align-items-center gap-2"
              style={{ color: '#fff' }}
            >
              <FaUser /> Edit Profile
            </Nav.Link>

            <Nav.Link
              href="#"
              onClick={() => setSelectedOption('files')}
              active={selectedOption === 'files'}
              className="d-flex align-items-center gap-2"
              style={{ color: '#fff' }}
            >
              <FaFolder /> Files
            </Nav.Link>

            <Nav.Link
              href="#"
              onClick={() => setSelectedOption('jobs')}
              active={selectedOption === 'jobs'}
              className="d-flex align-items-center gap-2"
              style={{ color: '#fff' }}
            >
              <FaFolder /> Jobs
            </Nav.Link>

            <Nav.Link
              href="#"
              onClick={() => setSelectedOption('ToS')}
              active={selectedOption === 'ToS'}
              className="d-flex align-items-center gap-2"
              style={{ color: '#fff' }}
            >
              <FaQuestionCircle /> Terms of Service
            </Nav.Link>

            {/* New Nav link for Chat bot */}
            <Nav.Link
              href="#"
              onClick={() => setSelectedOption('chatbot')}
              active={selectedOption === 'chatbot'}
              className="d-flex align-items-center gap-2"
              style={{ color: '#fff' }}
            >
              <FaQuestionCircle /> Chat Bot
            </Nav.Link>

          </Nav>
        </Col>

        {/* Main Content Area */}
        <Col xs={10} className="p-4" style={{ backgroundColor: '#f8f9fa', color:'#000' }}>
          <Row style={{color:'#000'}}>
            {/* Onboarding Steps */}
            <Col xs={3} className="pe-4" style={{color:'#000'}}>
              <div className="bg-white p-3 rounded shadow-sm mb-3" style={{color:'#000'}}>
                <h6 className="mb-3" style={{color:'#000'}}>Get Started</h6>
                <ListGroup variant="flush">
                  <ListGroup.Item className="d-flex justify-content-between align-items-center" style={{color:'#000'}}>
                    Update Your Profile 
                  </ListGroup.Item>
                  <ListGroup.Item style={{color:'#000'}}>View Xactimate Jobs</ListGroup.Item>
                  <ListGroup.Item style={{color:'#000'}}>Review Helpful Documentation</ListGroup.Item>
                  <ListGroup.Item style={{color:'#000'}}>Review our terms of service</ListGroup.Item>
                </ListGroup>
              </div>
              <div className="bg-white p-3 rounded shadow-sm" style={{color:'#000'}}>
                <h6 style={{color:'#000'}}>Dashboard</h6>
                <p className="small mb-0" style={{color:'#000'}}>Your quick stats & overview</p>
              </div>
            </Col>

            {/* Content Based on Selected Option */}
            <Col xs={9} style={{color:'#000'}}>
              <div className="bg-white p-4 rounded shadow-sm" style={{color:'#000'}}>
                <h5 className="mb-4" style={{color:'#000'}}>{getSectionTitle()}</h5>

                {selectedOption === 'editProfile' && (
                  <>
                    <Form onSubmit={handleSubmit} style={{color:'#000'}}>
                      <Row className="mb-3" style={{color:'#000'}}>
                        <Col>
                          <Form.Group controlId="formFirstName">
                            <Form.Label style={{color:'#000'}}>First Name</Form.Label>
                            <Form.Control
                              type="text"
                              name="firstName"
                              value={profileData.firstName}
                              onChange={handleChange}
                              style={{color:'#000'}}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId="formLastName">
                            <Form.Label style={{color:'#000'}}>Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              name="lastName"
                              value={profileData.lastName}
                              onChange={handleChange}
                              style={{color:'#000'}}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Form.Group controlId="formEmail" className="mb-3" style={{color:'#000'}}>
                        <Form.Label style={{color:'#000'}}>Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          value={profileData.email}
                          disabled
                          style={{color:'#000'}}
                        />
                      </Form.Group>

                      <Row className="mb-3" style={{color:'#000'}}>
                        <Col>
                          <Form.Group controlId="formOldPassword">
                            <Form.Label style={{color:'#000'}}>Old Password</Form.Label>
                            <Form.Control
                              type="password"
                              name="oldPassword"
                              value={profileData.oldPassword}
                              onChange={handleChange}
                              style={{color:'#000'}}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId="formNewPassword">
                            <Form.Label style={{color:'#000'}}>New Password</Form.Label>
                            <Form.Control
                              type="password"
                              name="newPassword"
                              value={profileData.newPassword}
                              onChange={handleChange}
                              style={{color:'#000'}}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Form.Group controlId="formVerifyNewPassword" className="mb-3" style={{color:'#000'}}>
                        <Form.Label style={{color:'#000'}}>Verify New Password</Form.Label>
                        <Form.Control
                          type="password"
                          name="verifyNewPassword"
                          value={profileData.verifyNewPassword}
                          onChange={handleChange}
                          style={{color:'#000'}}
                        />
                      </Form.Group>

                      <div className="d-flex align-items-center mb-3" style={{color:'#000'}}>
                        <Form.Label className="mb-0 me-2" style={{color:'#000'}}>AI Tokens:</Form.Label>
                        <span className="badge bg-secondary text-white">{profileData.tokenCount}</span>
                      </div>

                      <Button variant="primary" type="submit" className="mt-3 gradient-bg" >
                        Update Profile
                      </Button>
                    </Form>
                    {error && <div className="mt-4 text-danger" style={{color:'#000'}}>{error}</div>}
                  </>
                )}

                {selectedOption === 'files' && (
                  <>
                    {files.length > 0 ? (
                      <div className="table-responsive" style={{color:'#000'}}>
                        <Table striped bordered hover style={{color:'#000'}}>
                          <thead>
                            <tr style={{color:'#000'}}>
                              <th>#</th>
                              <th>File Name</th>
                              <th>Uploaded At</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {files.map((file, index) => (
                              <tr key={file.id} style={{color:'#000'}}>
                                <td>{index + 1}</td>
                                <td>{file.name}</td>
                                <td>{file.uploadedAt}</td>
                                <td>{file.status}</td>
                                <td className="d-flex justify-content-center gap-2">
                                  <Button
                                    variant="outline-primary"
                                    size="sm"
                                    disabled={file.status === 'Inactive'}
                                    onClick={() => handleViewFile(file.jobId, file.name)}
                                    style={{color:'#000'}}
                                  >
                                    View
                                  </Button>
                                  <Button
                                    variant="outline-secondary"
                                    size="sm"
                                    onClick={() => handleRestartJob( file.jobId)}
                                    disabled={file.status !== 'In Progress'}
                                    style={{color:'#000'}}
                                  >
                                    Restart
                                  </Button>
                                  <Button
                                    variant="outline-secondary"
                                    size="sm"
                                    onClick={() => handleViewEsxFile(file.name, file.jobId)}

                                    disabled={file.status !== 'Completed'}
                                    style={{color:'#000'}}
                                  >
                                    Download Esx
                                  </Button>
                                  <Button
                                    variant="outline-danger"
                                    size="sm"
                                    disabled={file.status === 'Inactive'}
                                    onClick={() => handleDeleteJob( file.jobId)}
                                    style={{color:'#000'}}
                                  >
                                    Delete
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    ) : (
                      <p style={{color:'#000'}}>You have no files uploaded.</p>
                    )}
                  </>
                )}

                {selectedOption === 'jobs' && (
                  <div style={{color:'#000'}}>
                    <p>Jobs section coming soon...</p>
                  </div>
                )}

                {selectedOption === 'ToS' && (
                  <div className="terms-container" style={{ maxHeight: '75rem', overflowY: 'auto', color:'#000' }}>
                    <p style={{ whiteSpace: 'pre-wrap', color:'#000' }}>
                      {termsText}
                    </p>
                  </div>
                )}

                {/* New chatbot condition */}
                {selectedOption === 'chatbot' && (
                  <div style={{color:'#000'}}>
                    <XactimateChatbotWidget onResponse={handleChatbotResponse} />
                  </div>
                )}

                {selectedOption !== 'editProfile' && selectedOption !== 'files' && selectedOption !== 'jobs' && selectedOption !== 'ToS' && selectedOption !== 'chatbot' && (
                  <div style={{color:'#000'}}>
                    <p>Please select an option from the left menu.</p>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default EditAccount;
