'use client';

import React, { useState, useEffect } from "react";
import { PDFViewer, PDFDownloadLink  } from '@react-pdf/renderer';
import XactimatePreviewer from './XactimatePdfPreviewer';
import logo from "../assets/logo-2.png";
import { toast } from "react-toastify";
import { generateEstimate, searchLineItems } from "../services/uploads/upload.service"; 
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Row, Image, Form, Card, Tabs, Tab } from "react-bootstrap";
import XactimateChatbotWidget from './chatbot/XactimateChatbotWidget';
import defaultNotesJson from '../default_notes.json';
import XactimatePdfHeaderPage from "./XactimatePdfHeaderPage";
const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: 'white',
    color: 'black',
    borderColor: '#ced4da',
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: 'white',
    color: 'black',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#e9ecef' : 'white',
    color: 'black',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'black',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#6c757d',
  }),
};

const XactimatePreviewPage = ({ estimatePdf, propertyPdf, data }) => {
  const [profileData, setProfileData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    oldPassword: '',
    newPassword: '',
    verifyNewPassword: '',
    tokenCount: 0,
  });

  const [items, setItems] = useState([]);
  const [error, setError] = useState("");
  const [selectedOption, setSelectedOption] = useState('editProfile'); 
  const [termsText, setTermsText] = useState('');
  const [defaultNotes, setDefaultNotes] = useState(defaultNotesJson); 
  const [invoiceData, setInvoiceData] = useState({
    number: 'INV-1001',
    clientName: 'John Doe',
    date: '2023-10-24',
    items: [],
    total: 0,
    itemsBySection: {},
  });

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;

  const sectionOptions = [
    { value: 'Roofing', label: 'Roofing' },
    { value: 'Interior', label: 'Interior' },
    { value: 'Siding', label: 'Siding' },
    { value: 'Misc', label: 'Misc' },
  ];

  const navigate = useNavigate();

  const fetchLineItems = async (inputValue) => {
    try {
      const formData = new FormData();
      formData.append('searchTerm', inputValue);  
      formData.append('page', 1);
      const response = await searchLineItems(formData);
      return response;
    } catch (error) {
      console.error('Error fetching line items:', error);
      toast.error('Error fetching line items. Please try again.');
      return null;
    }
  };

  const loadOptions = async (inputValue) => {
    if (!inputValue) {
      return [];
    }
    const response = await fetchLineItems(inputValue);
    if (response && response.data && response.data.lineItems) {
      const options = response.data.lineItems.map(item => ({
        value: item.Description,
        label: item.Description,
        componentCode: item.componentCode,
        unitPrice: item.UnitPrice || 0,
        unit: item.Unit,
      }));
      return options;
    }
    return [];
  };

  const removeItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };

  const parseData = (line_items) => {
    const lineItemsText = line_items || (data && data.line_items);
    
    if (!lineItemsText) {
        console.error('line_items is undefined or empty.');
        return;
    }

    const pattern = /\*\*(\d+):\s*(.*?)\s*:\*\*[\s\S]*?- \*\*Quantity:\*\* ([\d\.,]+ \w+)/g;
    const sectionPattern = /##\s*(\w+)/g;

    const matches = [...lineItemsText.matchAll(pattern)];
    const sections = [...lineItemsText.matchAll(sectionPattern)];

    if (!matches.length) {
        console.warn('No line items matched the pattern.');
        return;
    }

    let currentSection = 'Unknown'; 
    const parsedItems = [];
    let sectionIndex = 0;

    matches.forEach(match => {
        while (
            sectionIndex < sections.length &&
            match.index > sections[sectionIndex].index
        ) {
            currentSection = sections[sectionIndex][1];
            sectionIndex++;
        }

        parsedItems.push({
            type: 'lineItem',
            description: match[2].trim(),
            quantity: parseFloat(match[3].replace(',', '')) || 0,
            price: 0,
            notes: [],
            section: currentSection,
            componentCode: '',
            unitPrice: 0,
            unit: '',
        });
    });

    if (!parsedItems.length) {
        console.warn('No parsed items were generated.');
        return;
    }

    setItems(parsedItems);
  };

  useEffect(() => {
    if (data) {
      parseData();
    }
  }, [data]);

  const addLineItem = () => {
    setItems([
      ...items,
      {
        type: 'lineItem',
        description: '',
        quantity: 1,
        price: 0,
        notes: [],
        section: 'Roofing', 
        componentCode: '',
        unitPrice: 0,
        unit: '',
      },
    ]);
  };

  const handleItemChange = (index, field, value) => {
    const updatedItems = [...items];

    if (field === 'description') {
      const selectedOption = value;
      if (selectedOption) {
        updatedItems[index]['description'] = selectedOption.label;
        updatedItems[index]['componentCode'] = selectedOption.componentCode;
        updatedItems[index]['unitPrice'] = selectedOption.unitPrice || 0;
        updatedItems[index]['unit'] = selectedOption.unit;
        updatedItems[index]['price'] = selectedOption.unitPrice || 0;
      } else {
        updatedItems[index]['description'] = '';
        updatedItems[index]['componentCode'] = '';
        updatedItems[index]['unitPrice'] = 0;
        updatedItems[index]['unit'] = '';
        updatedItems[index]['price'] = 0;
      }

      if (selectedOption && defaultNotes[selectedOption.label]) {
        updatedItems[index].notes = JSON.parse(JSON.stringify(defaultNotes[selectedOption.label].value));
      } else {
        updatedItems[index].notes = [];
      }

    } else if (field === 'quantity') {
      updatedItems[index]['quantity'] = parseInt(value) || 1;
    } else {
      updatedItems[index][field] = value;
    }

    setItems(updatedItems);
  };

  useEffect(() => {
    const total = items.reduce(
      (sum, item) => sum + item.quantity * item.price,
      0
    );

    const itemsBySection = {};
    items.forEach(item => {
      const section = item.section || 'Section 1';
      if (itemsBySection[section]) {
        itemsBySection[section].push(item);
      } else {
        itemsBySection[section] = [item];
      }
    });

    // Ensure the PDF previewer always displays sections in the correct order
    const orderedSectionKeys = ['Roofing', 'Interior', 'Siding', 'Misc'];
    const orderedItemsBySection = {};
    orderedSectionKeys.forEach(sec => {
      if (itemsBySection[sec]) {
        orderedItemsBySection[sec] = itemsBySection[sec];
      }
    });

    setInvoiceData(prevData => ({
      ...prevData,
      items: items,
      itemsBySection: orderedItemsBySection,
      total: total,
    }));
  }, [items]);

  const totalPages = Math.ceil(items.length / itemsPerPage);

  const goToNextPage = () => {
    setCurrentPage(prev => Math.min(prev + 1, totalPages - 1));
  };

  const goToPreviousPage = () => {
    setCurrentPage(prev => Math.max(prev - 1, 0));
  };

  const renderItems = () => {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = items.slice(startIndex, endIndex);
  
    return currentItems.map((item, index) => {
      const globalIndex = startIndex + index; 
  
      return (
        <React.Fragment key={globalIndex}>
          <Card className="item-card mb-4">
            <Card.Body>
              <Row className="mb-3">
                <Col lg={4}>
                  <Form.Group>
                    <Form.Label style={{color:'#000'}}>Description</Form.Label>
                    <AsyncSelect
                      styles={customSelectStyles}
                      cacheOptions
                      loadOptions={loadOptions}
                      defaultOptions
                      onChange={(selectedOption) =>
                        handleItemChange(globalIndex, 'description', selectedOption)
                      }
                      placeholder="Select a line item..."
                      isClearable
                      value={item.description ? { label: item.description, value: item.description } : null}
                    />
                  </Form.Group>
                </Col>
                <Col lg={2}>
                  <Form.Group>
                    <Form.Label style={{color:'#000'}}>Qty</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Qty"
                      value={item.quantity}
                      onChange={(e) =>
                        handleItemChange(globalIndex, 'quantity', e.target.value)
                      }
                      style={{color:'#000'}}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group>
                    <Form.Label style={{color:'#000'}}>Section</Form.Label>
                    <Select
                      styles={customSelectStyles}
                      options={sectionOptions}
                      value={sectionOptions.find(option => option.value === item.section)}
                      onChange={(selectedOption) =>
                        handleItemChange(globalIndex, 'section', selectedOption ? selectedOption.value : '')
                      }
                      isClearable
                      isSearchable
                      placeholder="Select a section..."
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} className="d-flex align-items-end">
                  <Button
                    variant="danger"
                    onClick={() => removeItem(globalIndex)}
                    block
                  >
                    Remove
                  </Button>
                </Col>
              </Row>
  
              <Row className="mb-3">
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label style={{color:'#000'}}>Select Default Notes</Form.Label>
                    <Select
                      styles={customSelectStyles}
                      options={Object.keys(defaultNotes).map(key => ({value:key, label:key}))}
                      onChange={(selectedOption) => {
                        if (selectedOption && defaultNotes[selectedOption.value]) {
                          const updatedItems = [...items];
                          updatedItems[globalIndex].notes = JSON.parse(JSON.stringify(defaultNotes[selectedOption.value].value));
                          setItems(updatedItems);
                        }
                      }}
                      isClearable
                      isSearchable
                      placeholder="Select or type line item..."
                    />
                  </Form.Group>
                </Col>
              </Row>
  
              {item.notes && item.notes.length > 0 && (
              <div style={{border: '1px solid #ccc', padding: '10px', borderRadius:'5px'}}>
                {(item.description || item.quantity) && (
                  <>
                    <strong style={{color:'#000'}}>Description: {item.description}</strong><br/>
                    <strong style={{color:'#000'}}>Quantity: {item.quantity}</strong>
                    <hr/>
                  </>
                )}
                {item.notes.map((note, noteIndex) => (
                  <p key={noteIndex} style={{fontWeight: note.bold ? 'bold' : 'normal', color:'#000'}}>
                    {note.text}
                  </p>
                ))}
              </div>
            )}

            </Card.Body>
          </Card>
        </React.Fragment>
      );
    });
  };

  const handleChatbotResponse = (final_output) => {
    console.log('Chatbot final_output:', final_output);
    if (final_output) {
        try {
            parseData(final_output);
        } catch (error) {
            console.error('Error parsing action_output:', error);
        }
    }
  };

  const generateRandomString = (length = 4) =>{
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastId = toast.loading("Thank you, we have queued your Xactimate estimate."); 

    try {
      const formData = new FormData();
      formData.append("account_id", localStorage.getItem("accountId"));
      const randomString = generateRandomString();

      function renameFile(originalFile, newFilename) {
        return new File([originalFile], newFilename, {
          type: originalFile.type,
          lastModified: originalFile.lastModified,
        });
      }

      const estimatePdfName = estimatePdf.name || 'estimate.pdf';
      const propertyPdfName = propertyPdf.name || 'property.pdf';

      function getFileNameAndExtension(filename) {
        const lastDotIndex = filename.lastIndexOf('.');
        if (lastDotIndex === -1) {
          return { name: filename, extension: '' };
        }
        const name = filename.substring(0, lastDotIndex);
        const extension = filename.substring(lastDotIndex);
        return { name, extension };
      }

      const estimateFileParts = getFileNameAndExtension(estimatePdfName);
      const propertyFileParts = getFileNameAndExtension(propertyPdfName);

      const newEstimatePdfName = `${estimateFileParts.name}_${randomString}${estimateFileParts.extension}`;
      const newPropertyPdfName = `${propertyFileParts.name}_${randomString}${propertyFileParts.extension}`;

      const newEstimatePdf = renameFile(estimatePdf, newEstimatePdfName);
      const newPropertyPdf = renameFile(propertyPdf, newPropertyPdfName);

      formData.append("estimate_pdf", newEstimatePdf);
      formData.append("property_pdf", newPropertyPdf);
      formData.append("line_items", JSON.stringify(items));
      console.log("line_items", JSON.stringify(items));

      const headers = {"Content-Type": "multipart/form-data"};
      const response = await generateEstimate(formData, headers);
      toast.update(toastId, { render: "You can check your files for the status and we will notify the email on file once complete.", type: "success", isLoading: false, autoClose: 5000 });
      navigate("/editAccount");
      console.log("Response:", response);
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const handleDefaultNoteKeyChange = (oldKey, newKey) => {
    if (newKey.trim() === '') return;
    const updatedNotes = {...defaultNotes};
    if (oldKey !== newKey) {
      updatedNotes[newKey] = updatedNotes[oldKey];
      delete updatedNotes[oldKey];
    }
    setDefaultNotes(updatedNotes);
  };

  const handleDefaultNoteValueChange = (key, index, field, value) => {
    const updatedNotes = {...defaultNotes};
    updatedNotes[key].value[index][field] = value;
    setDefaultNotes(updatedNotes);
  };

  const handleAddNewDefaultNote = () => {
    const updatedNotes = {...defaultNotes};
    updatedNotes["New Line Item"] = {
      note: "New Line Item",
      value: [
        {text: "New note text", bold:false}
      ]
    };
    setDefaultNotes(updatedNotes);
  };

  const renderDefaultNotesEditor = () => {
    return (
      <div style={{maxHeight:'75rem', overflowY:'auto', color:'#000'}}>
        <Button variant="secondary" onClick={handleAddNewDefaultNote} className="mb-3">Add New Default Note Entry</Button>
        {Object.keys(defaultNotes).map((key, noteIndex) => (
          <Card key={key} className="mb-3">
            <Card.Body>
              <Form.Group className="mb-3">
                <Form.Label style={{color:'#000'}}>Line Item Name</Form.Label>
                <Form.Control
                  type="text"
                  value={key}
                  onChange={(e) => handleDefaultNoteKeyChange(key, e.target.value)}
                  style={{color:'#000'}}
                />
              </Form.Group>

              {defaultNotes[key].value.map((val, vIndex) => (
                <div key={vIndex} style={{marginBottom:'10px'}}>
                  <Form.Group>
                    <Form.Label style={{color:'#000'}}>Text</Form.Label>
                    <Form.Control
                      type="text"
                      style={{color:'#000'}}
                      value={val.text}
                      onChange={(e) => handleDefaultNoteValueChange(key, vIndex, 'text', e.target.value)}
                    />
                  </Form.Group>
                  <Form.Check
                    type="checkbox"
                    label="Bold"
                    checked={val.bold}
                    onChange={(e) => handleDefaultNoteValueChange(key, vIndex, 'bold', e.target.checked)}
                    style={{color:'#000'}}
                  />
                </div>
              ))}
            </Card.Body>
          </Card>
        ))}
      </div>
    );
  };

  return (
    <Container className="my-5 gradient-bg" style={{ maxWidth: "100%", padding: "20px", borderRadius: "8px" }}>
      <Row>
        <Col lg={12} className="text-center mb-3" style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.2)", color: "#ffffff" }}>
          <Image src={logo} />
          <h1 className="mt-5 mb-4">Xactimate Estimate</h1>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col lg={12}>
          <p style={{ color: "#ffffff", fontSize: "16px", textAlign: "center" }}>
            This document provides an estimate for the requested work. Please review carefully.
          </p>
        </Col>
      </Row>
      <Row style={{backgroundColor:"#525659"}}>
        <Col lg={6} className="my-4" style={{height:"75rem"}} >
          <PDFViewer width="100%" height="100%">
            < XactimatePreviewer data={invoiceData} />
          </PDFViewer>
          
        </Col>
        <Col lg={6} className="my-4" style={{height:"75rem", overflow:"auto"}}>
          <Tabs activeKey={selectedOption} onSelect={(k) => setSelectedOption(k)} id="content-tabs" className="mb-3" >
            <Tab eventKey="editProfile" title="Line Items" style={{color:"white"}}>
              {renderItems()}

              {Math.ceil(items.length / itemsPerPage) > 1 && (
                <Row className="mb-4 align-items-center">
                  <Col xs={3}></Col>
                  <Col xs={6} className="d-flex justify-content-center">
                    <Button
                      variant="secondary"
                      onClick={() => goToPreviousPage()}
                      disabled={currentPage === 0}
                      className="mr-2"
                    >
                      Previous
                    </Button>
                    <span
                      className="align-self-center mx-2"
                      style={{ color: "#ffffff" }}
                    >
                      Page {currentPage + 1} of {totalPages}
                    </span>
                    <Button
                      variant="secondary"
                      onClick={() => goToNextPage()}
                      disabled={currentPage === totalPages - 1}
                      className="ml-2"
                    >
                      Next
                    </Button>
                  </Col>
                  <Col xs={3} className="d-flex justify-content-end">
                    <Button variant="primary" onClick={addLineItem}>
                      Add Line Item
                    </Button>
                  </Col>
                </Row>
              )}
            </Tab>
            <Tab eventKey="oliver" title="Oliver">
              <XactimateChatbotWidget onResponse={handleChatbotResponse} estimatePdf={data.line_items} propertyPdf={propertyPdf} />
            </Tab>
            <Tab eventKey="ToS" title="Default Notes" style={{color:'black'}}>
              {renderDefaultNotesEditor()}
            </Tab>
          </Tabs>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="d-flex justify-content-center">
            {/* Download Link for second-page-only PDF */}
            <Button
            className="step-button border-0 px-4 py-2"
       
            style={{ backgroundColor:'white'}}
          >
            <PDFDownloadLink
              document={<XactimatePdfHeaderPage data={invoiceData} clientInfo={data}/>}
              fileName="second-page-only.pdf"
              style={{ textDecoration: 'none' }}
            >
              {({ blob, url, loading, error }) => {
                if (loading) return 'Loading document...';
                if (error) return 'Error generating document';

                return (
                  <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'rgb(19 11 60)', textDecoration: 'none' }} // Inline styles
                  > 
                    Preview Xactimate
                  </a>
                );
              }}

      </PDFDownloadLink>
      </Button>
          <Button
            className="step-button border-0 px-4 py-2"
            onClick={handleSubmit}
            style={{ color:'#fff'}}
          >
            <p className="m-0">Generate Estimate</p>
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default XactimatePreviewPage;
