// Estimate.jsx
import React, { useState } from "react";
import { Button, Col, Container, Image, Nav, Card, Row } from "react-bootstrap";
import { getAccessories } from "../services/uploads/upload.service";
import logo from "../assets/logo-2.png";
import FileUploader from "./FileUploader";
import { toast } from "react-toastify";
const Estimate = ({ currentStep, goToNextStep, goToStepTwo }) => {
  const [estimatePdf, setEstimatePdf] = useState(null); // For uploaded Estimate PDF
  const [propertyPdf, setPropertyPdf] = useState(null); // For uploaded Property PDF

  const tokenCount = localStorage.getItem("tokenCount");
  const headers = {
    "Content-Type": "multipart/form-data"
  };

  const handleNext = async () => {
    try {
      if (!estimatePdf || !propertyPdf) {
        toast.error("Please upload both Estimate PDF and Property PDF.");
        return;
      }
  
      setLoading(true);
  
      // Create a FormData object to send the files to the backend
      const formData = new FormData();
      formData.append("estimate_pdf", estimatePdf);
      formData.append("property_pdf", propertyPdf);
  
      try {
        // Call the backend API to validate the PDFs
        const response = await getAccessories(formData,headers); // Adjust based on your API function
        const { isReadable } = response.data;
  
        if (!isReadable) {
          toast.error("The Estimate PDF appears to be non-readable (possibly images only).");
          setLoading(false);
          return;
        }
  
        // Proceed to the next step if the PDF is readable
        goToStepTwo(1, estimatePdf, propertyPdf);
      } catch (error) {
        console.error("Error during backend validation:", error);
        toast.error("Error validating PDF readability. Please try again.");
      } finally {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      setLoading(false);
      toast.error("Error uploading files. Please try again.");
    }
  };
  

  // Loading state management
  const [loading, setLoading] = useState(false);

  return (
    <Container className="my-5">
      <Card className="main-card">
        {/* Header Section */}
        <Row>
          <Col
            lg={12}
            className="text-center mb-3 header-col"
          >
            <Image src={logo} fluid />
            <h1 className="mt-5 mb-4">UPLOAD FILES</h1>
            <h5 className="mt-2 mb-4">Cortex Tokens: {tokenCount}</h5>
          </Col>
        </Row>

        {/* Navigation Tabs */}
        <Nav variant="tabs" className="mb-4 justify-content-center">
          <Nav.Item>
            <Nav.Link active={true} className="me-2 upload-pdfs-nav-link">
              Upload PDFs
            </Nav.Link>
          </Nav.Item>
        </Nav>

        {/* Instruction Paragraph */}
        <Row className="mb-4">
          <Col lg={12}>
            <p className="instruction-paragraph">
              Please upload your Estimate PDF and Property Measurements PDF using the file uploaders below. Hover over the file uploaders to see more options.
            </p>
          </Col>
        </Row>

        {/* File Uploader Components */}
        <Row className="mb-4">
          <Col lg={6} className="file-uploader-col file-uploader">
            <FileUploader
              title="Upload Contractor Estimate"
              fileType="application/pdf"
              category="estimate"
              setFile={setEstimatePdf}
              file={estimatePdf}
              className="file-uploader"
            />
          </Col>
          <Col lg={6} className="file-uploader-col file-uploader">
            <FileUploader
              title="Upload Property Measurements"
              fileType="application/pdf"
              category="property"
              setFile={setPropertyPdf}
              file={propertyPdf}
              className="file-uploader"
            />
          </Col>
        </Row>

        {/* Next Step Button */}
        <Row>
          <Col
            lg={12}
            className="d-flex justify-content-center my-4"
          >
            {currentStep !== 2 && (
              <Button
                className="step-button border-0 px-4 py-2"
                onClick={handleNext}
                disabled={loading}
              >
                {loading ? "Processing..." : "NEXT STEP"}
              </Button>
            )}
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default Estimate;
