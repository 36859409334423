// XactimatePdfHeaderPage.jsx

import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import logo from "../assets/logo-2.png";

// Styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 10,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 10,
    width: '100%',
  },
  logo: {
    width: 80,
    height: 40,
    marginRight: 10,
  },
  infoRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',  // pushes children to far-left / far-right
    marginBottom: 3,
  },
  companyInfo: {
    fontSize: 9,
    flex: 1,
  },
  companyInfoLine: {
    borderBottomWidth: 2,
    borderBottomColor: 'black',
    width: '100%',
    marginTop: 2,
    marginBottom: 5,
  },
  pageTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 15,
    textAlign: 'center',
  },

  // New styles for the info layout:
  infoBlock: {
    marginBottom: 10,
  },

  infoLabel: {
    marginRight: 4,
    fontWeight: 'extrabold',
  },
  infoValue: {
    marginRight: 15, // spacing between sets
  },
  separatorLine: {

    marginTop: 8,
    marginBottom: 8,
  },
  page: { 
    padding: 30, 
    fontSize: 10 
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 20,
    width: '100%',
  },
  logo: { 
    width: 80, 
    height: 40, 
    marginRight: 10 
  },
  companyInfo: { 
    fontSize: 9,
    flex: 1,
  },
  companyInfoLine: { 
    borderBottomWidth: 2,
    borderBottomColor: 'black',
    width: '100%',
    marginTop: 2,
    marginBottom: 5,
  },
  title: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  sectionContainer: {
    marginBottom: 20,
  },
  sectionHeaderContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  sectionHeader: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  sectionHeaderUnderline: {
    borderBottomWidth: 2,
    borderBottomColor: 'black',
    flex: 1,
    marginLeft: 10,
  },
  table: { 
    display: 'table', 
    width: 'auto', 
  },
  tableRowHeader: { 
    flexDirection: 'row',
    borderBottomWidth: 2,
    borderBottomColor: 'black',
    marginBottom: 5,
  },
  tableRow: { 
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '8.5%',
    padding: 5,
    textAlign: 'center',
  },
  tableCol: {
    width: '8.5%',
    padding: 5,
    textAlign: 'center',
  },
  descriptionCol: {
    width: '35%',
    padding: 5,
    textAlign: 'left',
  },
  noCol: {
    width: '5%',
    padding: 5,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  tableCellHeader: { 
    fontSize: 9, 
    fontWeight: 'bold' 
  },
  tableCell: { 
    fontSize: 9 
  },
  noteRow: {
    flexDirection: 'row',
    marginTop: 2,
    marginBottom: 2,
  },
  noteCell: {
    flex: 1,
    padding: 5,
    fontSize: 9,
    fontStyle: 'italic',
  },
  boldNote: {
    fontWeight: 'bold',
    fontSize: 12,
    color: 'black',
  },

});

const XactimatePdfHeaderPage = ({ data, clientInfo}) => {
  console.log("XactimatePdfHeaderPage data: ");
  console.log(data);
  const sections = Object.keys(data.itemsBySection);
  const claimInfo = clientInfo.extracted_info;
  // You can pull these fields from "data" or hardcode them for now:
  const insuredName = claimInfo.estimator;
  const property = claimInfo.street_address + "\n" + "," + claimInfo.state + claimInfo.zip_code + "\n" + claimInfo.city;
  const claimRepName = claimInfo.estimator;
  const claimRepBusiness = "(410) 555-3081";
  const claimRepCompany = "AK Public Adjusters";

  const estimatorName = claimInfo.estimator;
  const estimatorBusiness = "(410) 555-3081";
  const estimatorCompany = "AK Public Adjusters";

  const claimNumber = claimInfo.claim_number;
  const policyNumber = claimInfo.policy_number;
  const typeOfLoss = "Windstorm, Hail, & WindBlown Debris";

  const dateContacted = "7/31/2024 12:00 AM";
  const dateOfLoss = claimInfo.date_of_loss;
  const dateReceived = "7/31/2024 12:00 AM";
  const dateEntered = "12/27/2024 11:08 AM";

  const priceList = "MDRO8X_DEC24\nRestoration/Service/Remodel";
  const estimate = "1 gazillion bucks :)"; 
  return (
    <Document>
      <Page size="A4" style={styles.page}>
  {/* Header */}
  <View style={styles.headerContainer}>
    <Image style={styles.logo} src={logo} />
    <View style={styles.companyInfo}>
      <Text>AK Public Adjusters</Text>
      <View style={styles.companyInfoLine} />
      <Text>11436 Cronridge Drive, Suite E</Text>
      <Text>Owings Mills MD 21117</Text>
    </View>
  </View>

  {/* Page Title (Optional) */}
  <Text style={styles.pageTitle}>Xactimate Preview Page</Text>

  {/* Insured & Property Block */}
  <View style={styles.infoBlock}>
    {/* Row 1: "Insured" on the left, "Property" on the right (if needed) */}
    <View style={styles.infoRow}>
      <View style={{ flexDirection: 'row' }}>
        <Text style={styles.infoLabel}>Insured:</Text>
        <Text style={styles.infoValue}>{insuredName}</Text>
      </View>
    </View>
    <View style={styles.infoRow}>
      <View style={{ flexDirection: 'row' }}>
        <Text style={styles.infoLabel}>Property:</Text>
        <Text>{property}</Text>
      </View>
    </View>
  </View>
  <View style={styles.separatorLine} />

  {/* Claim Rep & Estimator Block */}
  <View style={styles.infoBlock}>

    {/* Single row for Claim Rep */}
    <View style={styles.infoRow}>
      {/* Left group: "Claim Rep." and Name */}
      <View style={{ flexDirection: 'row' }}>
        <Text style={styles.infoLabel}>Claim Rep.:</Text>
        <Text style={styles.infoValue}>{claimRepName}</Text>
      </View>
    </View>
    <View style={styles.infoRow}>
      {/* Left group: "Claim Rep." and Name */}
      <View style={{ flexDirection: 'row' }}>
        <Text style={styles.infoLabel}>Company:</Text>
        <Text>{claimRepCompany}</Text>
      </View>

      {/* Right group: Business */}
      <View style={{ flexDirection: 'row' }}>
        <Text style={styles.infoLabel}>Business:</Text>
        <Text style={styles.infoValue}>{claimRepBusiness}</Text>
      </View>
    </View>
    {/* Second row for Estimator */}
    <View style={[styles.infoRow, { marginTop: 8 }]}>
      {/* Left group: "Estimator" and Name */}
      <View style={{ flexDirection: 'row' }}>
        <Text style={styles.infoLabel}>Estimator:</Text>
        <Text style={styles.infoValue}>{estimatorName}</Text>
      </View>

    </View>
    <View style={styles.infoRow}>
      {/* Left group: "Claim Rep." and Name */}
      <View style={{ flexDirection: 'row' }}>
        <Text style={styles.infoLabel}>Company:</Text>
        <Text>{estimatorCompany}</Text>
      </View>

      {/* Right group: Business */}
      <View style={{ flexDirection: 'row' }}>
        <Text style={styles.infoLabel}>Business:</Text>
        <Text style={styles.infoValue}>{estimatorBusiness}</Text>
      </View>
    </View>
  </View>
  <View style={styles.separatorLine} />

  {/* Claim Number & Policy Info */}
  <View style={styles.infoBlock}>
    <View style={styles.infoRow}>
      <View style={{ flexDirection: 'row' }}>
        <Text style={styles.infoLabel}>Claim Number:</Text>
        <Text style={styles.infoValue}>{claimNumber}</Text>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <Text style={styles.infoLabel}>Policy Number:</Text>
        <Text style={styles.infoValue}>{policyNumber}</Text>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <Text style={styles.infoLabel}>Type of Loss:</Text>
        <Text>{typeOfLoss}</Text>
      </View>
    </View>
  </View>
  <View style={styles.separatorLine} />

  {/* Dates Block */}
  <View style={styles.infoBlock}>
    <View style={styles.infoRow}>
      <View style={{ flexDirection: 'row' }}>
        <Text style={styles.infoLabel}>Date Contacted:</Text>
        <Text style={styles.infoValue}>{dateContacted}</Text>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <Text style={styles.infoLabel}>Date of Loss:</Text>
        <Text>{dateOfLoss}</Text>
      </View>
    </View>

    <View style={[styles.infoRow, { marginTop: 3 }]}>
      <View style={{ flexDirection: 'row' }}>
        <Text style={styles.infoLabel}>Date Received:</Text>
        <Text style={styles.infoValue}>{dateReceived}</Text>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <Text style={styles.infoLabel}>Date Entered:</Text>
        <Text>{dateEntered}</Text>
      </View>
    </View>
  </View>
  <View style={styles.separatorLine} />

  {/* Price List & Estimate */}
  <View style={styles.infoBlock}>
    <View style={styles.infoRow}>
      <View style={{ flexDirection: 'row' }}>
        <Text style={styles.infoLabel}>Price List:</Text>
        <Text style={styles.infoValue}>{priceList}</Text>
      </View>
    </View>
  </View>
  <View style={styles.infoBlock}>
    <View style={styles.infoRow}>
      <View style={{ flexDirection: 'row' }}>
        <Text style={styles.infoLabel}>Estimate:</Text>
        <Text>{estimate}</Text>
      </View>
    </View>
  </View>
  <View style={styles.separatorLine} />

  {/* ...Further sections or tables... */}
</Page>
<Page size="A4" style={styles.page}>
        {/* Header */}
        <View style={styles.headerContainer}>
          <Image style={styles.logo} src={logo} />
          <View style={styles.companyInfo}>
            <Text>AK Public Adjusters</Text>
            <View style={styles.companyInfoLine}></View>
            <Text>11436 Cronridge Drive, Suite E</Text>
            <Text>Owings Mills MD 21117</Text>
          </View>
        </View>

        {/* Title */}
        <Text style={styles.title}>XActimate Preview</Text>

        {/* Sections and Their Tables */}
        {sections.map((sectionName, idx) => (
          <View style={styles.sectionContainer} key={idx}>
            {/* Section Header with Underline Extending to the End of the Page */}
            <View style={styles.sectionHeaderContainer}>
              <Text style={styles.sectionHeader}>{sectionName}</Text>
              <View style={styles.sectionHeaderUnderline}></View>
            </View>

            {/* Table for the Section */}
            <View style={styles.table}>
              {/* Table Header Row */}
              <View style={styles.tableRowHeader}>
                {/* Numbering Column Header */}
                <View style={styles.noCol}>
                  <Text style={styles.tableCellHeader}>No.</Text>
                </View>
                {/* Description Column Header */}
                <View style={styles.descriptionCol}>
                  <Text style={styles.tableCellHeader}>DESCRIPTION</Text>
                </View>
                {/* Other Column Headers */}
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>QUANTITY</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>UNIT PRICE</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>TAX</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>O&P</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>RCV</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>DEPREC.</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>ACV</Text>
                </View>
              </View>

              {/* Items in Section */}
              {data.itemsBySection[sectionName].map((item, index) => (
                <React.Fragment key={index}>
                  {/* Line Item Row */}
                  <View style={styles.tableRow}>
                    {/* Numbering Column */}
                    <View style={styles.noCol}>
                      <Text style={styles.tableCell}>{index + 1}</Text>
                    </View>
                    {/* Description Column */}
                    <View style={styles.descriptionCol}>
                      <Text style={styles.tableCell}>{item.description || 'N/A'}</Text>
                    </View>
                    {/* Other Columns */}
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{item.quantity || 0}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        ${item.price ? item.price.toFixed(2) : '0.00'}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>${item.tax || 0}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>${item.oAndP || 0}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>${item.rcv || 0}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>${item.deprec || 0}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>${item.acv || 0}</Text>
                    </View>
                  </View>

                  {/* Notes Rows (if any) */}
                  {item.notes && item.notes.map((note, noteIndex) => (
                    <View style={styles.noteRow} key={`note-${index}-${noteIndex}`}>
                      {/* Empty numbering column for alignment */}
                      <View style={styles.noCol}></View>
                      {/* Note content spanning the rest of the table width */}
                      <View style={{ flexDirection: 'row', flex: 1 }}>
                        <View style={styles.noteCell}>
                          <Text style={note.bold ? styles.boldNote : {}}>{note.text}</Text>
                        </View>
                      </View>
                    </View>
                  ))}
                </React.Fragment>
              ))}

              {/* Removed the extra notes mapping that caused notes to group together */}
            </View>
          </View>
        ))}

        {/* Footer (if needed) */}
        {/* <Text style={styles.footerText}>Your footer content here</Text> */}
      </Page>
    </Document>
  );
};

export default XactimatePdfHeaderPage;
