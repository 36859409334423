import React, { useEffect, useState } from 'react';
import { isAuthenticated2 } from "../services/account/account.service";
import LoadingPage from '../components/LoadingPage';

const SemiPrivateRoute = ({ children }) => {
  // Define the login URL based on the environment
  const loginURL =  "https://claimscortex.com";
console.log("loginURL",loginURL);
  // Initialize authentication state
  const [authState, setAuthState] = useState({
    loading: true,
    isAuth: false,
    accountId: null,
    loadingDelay: true
  });

  useEffect(() => {
    // Create a random delay between 2-5 seconds
    const delay = Math.floor(Math.random() * (5000 - 2000 + 1)) + 2000;
    
    const delayTimer = setTimeout(() => {
      setAuthState(prev => ({
        ...prev,
        loadingDelay: false
      }));
    }, delay);

    return () => clearTimeout(delayTimer);
  }, []);

  useEffect(() => {
    const authenticate = async () => {
      try {
        // Extract token from URL parameters
        const urlParams = new URLSearchParams(window.location.search);
        const authTokenFromURL = urlParams.get('token');
        const headers = {
          "Content-Type": "application/json"
        };

        let authToken = authTokenFromURL;

        if (authTokenFromURL) {
          // Call the authentication API with the token from URL
          const response = await isAuthenticated2({ authToken: authTokenFromURL }, headers);
          if (response.data.isAuthenticated) {
            // Store tokens and account information in localStorage
            localStorage.setItem('authToken', authTokenFromURL);
            localStorage.setItem('tokenCount', response.data.tokenCount);
            localStorage.setItem('accountId', response.data.accountId);
            console.log("response.data.accountId",response.data.accountId);
            // Update state to reflect authenticated status
            setAuthState(prev => ({
              ...prev,
              loading: false,
              isAuth: true,
              accountId: response.data.accountId,
            }));
            window.location.href = loginURL;

            return;
          }
        }

        // If there's no token in URL or authentication failed, check localStorage
        const storedAuthToken = localStorage.getItem('authToken');
        const storedAccountId = localStorage.getItem('accountId');
        if (storedAuthToken) {
          // Optionally, re-validate the stored token
          const response = await isAuthenticated2({ authToken: storedAuthToken }, headers);
          localStorage.setItem('tokenCount', response.data.tokenCount);
          localStorage.setItem('accountId', response.data.accountId);
          if (response.data.isAuthenticated) {
            setAuthState(prev => ({
              ...prev,
              loading: false,
              isAuth: true,
              accountId: storedAccountId,
            }));
            return;
          }
        }

        // If authentication fails, set isAuth to false
        setAuthState(prev => ({
          ...prev,
          loading: false,
          isAuth: false,
          accountId: null,
        }));
      } catch (error) {
        console.error("Authentication error:", error);
        setAuthState(prev => ({
          ...prev,
          loading: false,
          isAuth: false,
          accountId: null,
        }));
      }
    };

    // Only start authentication after loading delay
    if (!authState.loadingDelay) {
      authenticate();
    }
  }, [authState.loadingDelay]);

  useEffect(() => {
    if (!authState.loading && !authState.isAuth && !authState.loadingDelay) {
      // Redirect to the external login URL
       window.location.href = loginURL;
    }
  }, [authState, loginURL]);

  // Only show loading page during initial delay or authentication process
  if (authState.loadingDelay || authState.loading) {
    return <LoadingPage />;
  }

  // If authenticated, render the child components
  // If not authenticated, the redirect is already triggered
  return authState.isAuth ? children : null;
};

export default SemiPrivateRoute;