import React, { useState, useEffect } from 'react';

const LoadingPage = () => {
  const [dots, setDots] = useState('');
  const [progressWidth, setProgressWidth] = useState(0);

  useEffect(() => {
    // Animate loading dots
    const dotInterval = setInterval(() => {
      setDots(prev => (prev.length >= 3 ? '' : prev + '.'));
    }, 500);

    // Progress bar animation
    const progressInterval = setInterval(() => {
      setProgressWidth(prev => {
        if (prev >= 100) clearInterval(progressInterval);
        return prev < 100 ? prev + 1 : 100;
      });
    }, 50);

    return () => {
      clearInterval(dotInterval);
      clearInterval(progressInterval);
    };
  }, []);

  return (
    <div style={styles.container}>
      <div style={styles.glowBackground}></div>
      <div style={styles.contentWrapper}>
        {/* Replace the SVG with the spinner div */}
        <div className="spinner" />

        <h1 style={styles.title}>AI System Initializing{dots}</h1>
        <p style={styles.subtitle}>Preparing neural networks and cognitive processes</p>
        
        <div style={styles.progressContainer}>
          <div 
            style={{
              ...styles.progressBar,
              width: `${progressWidth}%`
            }}
          ></div>
        </div>
        
        <div style={styles.infoContainer}>
          <div style={styles.infoItem}>
            <span style={styles.infoLabel}>Neural Syncing Oliver</span>
            <span style={styles.infoValue}>92%</span>
          </div>
          <div style={styles.infoItem}>
            <span style={styles.infoLabel}>Data Processing</span>
            <span style={styles.infoValue}>78%</span>
          </div>
          <div style={styles.infoItem}>
            <span style={styles.infoLabel}>System Calibration</span>
            <span style={styles.infoValue}>64%</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    position: 'relative',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#0a0a1a',
    overflow: 'hidden',
    color: 'rgba(82, 52, 226, 1)',
    fontFamily: "'Orbitron', sans-serif",
  },
  glowBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'radial-gradient(circle at center, rgba(82, 52, 226, 1) 0%, (189, 38, 249, 1) 70%)',
    animation: 'pulse 3s infinite alternate',
  },
  contentWrapper: {
    textAlign: 'center',
    zIndex: 10,
    maxWidth: '500px',
    padding: '20px',
  },
  title: {
    fontSize: '2.5rem',
    marginBottom: '15px',
    color: 'rgb(80, 181, 253)',
    textShadow: '0 0 10px rgba(105, 43, 237, 0.5)',
  },
  subtitle: {
    fontSize: '1rem',
    color: 'rgb(57, 192, 242)',
    marginBottom: '30px',
  },
  progressContainer: {
    backgroundColor: 'rgba(82, 52, 226, 0.2)',
    height: '10px',
    borderRadius: '5px',
    overflow: 'hidden',
    marginBottom: '30px',
  },
  progressBar: {
    height: '100%',
    backgroundColor: 'rgba(82, 52, 226, 1)',
    transition: 'width 0.5s ease-out',
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  infoItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  infoLabel: {
    fontSize: '0.8rem',
    color: 'rgba(82, 52, 226, 0.6)',
    marginBottom: '5px',
  },
  infoValue: {
    fontSize: '1rem',
    color: 'rgba(82, 52, 226, 1)',
  },
  '@keyframes pulse': {
    '0%': { transform: 'scale(1)' },
    '100%': { transform: 'scale(1.05)' },
  },
};

export default LoadingPage;
