import React from 'react';
import { Navigate } from 'react-router-dom';

const PublicRoute = ({ children }) => {
  const loginURL =
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:3000"
    : "https://claimscortex.com";

    console.log('Navigation');

  return children
};

export default PublicRoute;
